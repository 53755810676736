.accordion {
  --rrs-accordion-active-color: var(--rrs-primary);
  --rrs-accordion-border-radius: none;
  --rrs-accordion-border-width: 0;

  --rrs-accordion-btn-padding-x: 0.5rem;
  --rrs-accordion-btn-padding-y: 1.25rem;
  --rrs-accordion-btn-bg: white;
  --rrs-accordion-active-bg: white;

  --rrs-accordion-btn-focus-border-color: none;
  --rrs-accordion-btn-focus-box-shadow: none;

  --rrs-accordion-btn-icon: none;
  --rrs-accordion-btn-active-icon: none;

  .active .accordion-button {
    background-color: var(--rrs-gray-100);
  }

  &-item {
    overflow: hidden;
    border-bottom: 1px solid var(--rrs-gray-400);
    transition: background-color 0.3s;

    &.active {
      background-color: var(--rrs-gray-100);
    }
  }

  &-button {
    &::after {
      width: auto;
      height: auto;
      font-weight: bold;
      content: '+';
      transition: all 0.3s;
      rotate: -90deg;
    }
    &:disabled {
      color: var(--rrs-gray-400);
      cursor: not-allowed;
    }
  }

  & .active .accordion-button {
    &::after {
      content: '-';
      rotate: -180deg;
      translate: -2px -2px;
    }
  }

  &--nearby-stores {
    .accordion-item {
      border-bottom: 0;
      &.active {
        background-color: transparent;

        rrs-icon {
          transform: rotate(180deg);
        }
      }
    }
  }
}

// Accordion Transition
.accordion-item {
  rrs-icon {
    cursor: pointer;
    transition: transform 300ms ease;
  }

  &.active {
    rrs-icon.accordion-icon {
      transform: rotate(180deg);
    }
  }
}

// Accordion Variants Styles - Not Working In Component Styleshhets
.accordion--cart,
.accordion--donate {
  border-bottom: 1px solid var(--rrs-gray-100);

  .accordion-item {
    border-bottom: 0;

    &.active {
      background-color: var(--rrs-white);
    }
  }
}

.accordion--donate {
  .accordion-item {
    &.active {
      overflow: visible;
    }
  }
}
