.badge {
  --rrs-badge-border-radius: 0.25rem;
  --rrs-badge-font-size: 0.75rem;
  --rrs-badge-font-weight: 600;
  --rrs-badge-padding-x: 0.5rem;
  --rrs-badge-padding-y: 0.125rem;

  display: flex;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;

  text-transform: uppercase;
}
.bogo-badge {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  text-decoration-line: underline;
}
