.header-menu-mobi-item {
  --rrs-mobile-header-height: 122px;
  &__link {
    border-bottom: 1px solid var(--rrs-gray-400);
    text-transform: uppercase;
    width: 100%;

    &--capitalize {
      text-transform: capitalize;
    }
  }

  &--l1 {
    .header-menu-mobi-item__link {
      &[class*='text'] {
        font-weight: 700;
      }
    }
  }
}
