// Inputs
$input-btn-focus-color-opacity: 0.25 !default;
$input-btn-focus-color: rgba(
  $color-black,
  $input-btn-focus-color-opacity
) !default;

$input-color: $color-black;
$input-line-height: 1.325;
$input-padding-x: 0.75rem;
$input-padding-y: 0.75rem;
$input-placeholder-color: $color-gray-800;

$input-disabled-color: $color-gray-500;
$input-disabled-bg: $color-gray-500;
$input-disabled-border-color: $color-gray-500;

$input-border-color: $color-gray-800;
$input-border-radius: 0.125rem;
$input-focus-border-color: $color-black;
$input-focus-box-shadow: none;
