@each $color, $value in $theme-colors {
  .link-#{$color} {
    color: $value !important;
    font-weight: 700;
    text-decoration: underline;

    @if $link-shade-percentage != 0 {
      &:hover,
      &:focus {
        color: if(
          color-contrast($value) == $color-contrast-light,
          shade-color($value, $link-shade-percentage),
          tint-color($value, $link-shade-percentage)
        ) !important;
      }
    }
  }
}

.link-disabled {
  color: $color-gray-500 !important;

  &:hover,
  &:focus {
    cursor: default;
    color: $color-gray-500 !important;
  }
}
