.mobile-app-banner {
  h3 {
    text-transform: unset;
  }

  img {
    max-width: 25rem;
    width: 100%;
  }
}
