// Fonts
$font-family-sans-serif: 'Open Sans';
$headings-font-family: 'Montserrat';

$font-size-base: 1rem; // 16px
$headings-font-weight: 700;

$h1-font-size: $font-size-base * 3; // 48px
$h2-font-size: $font-size-base * 2.25; // 36px
$h3-font-size: $font-size-base * 1.5; // 24px
$h4-font-size: $font-size-base * 1.125; //18px
$h5-font-size: $font-size-base; // 16px
$h6-font-size: $font-size-base * 0.875; // 14px
$h7-font-size: $font-size-base * 0.75; // 12px

$font-sizes: (
  1: $h1-font-size,
  2: $h2-font-size,
  3: $h3-font-size,
  4: $h4-font-size,
  5: $h5-font-size,
  6: $h6-font-size,
  7: $h7-font-size,
);
