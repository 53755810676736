.rich-text {
  a {
    text-decoration: underline;
  }

  img {
    margin-bottom: 1rem;
    width: 100%;
  }
}
