.curalate {
  text-align: center;
}

.curalate--padding-top {
  padding-top: 2rem;
}

.curalate__heading {
  font-size: 1.5rem;
}

.curalate__paragraph {
  font-size: 0.875rem;
  margin-bottom: 2rem;
}

.crl8-gallery-masonry {
  font-family: 'Open Sans';
}
