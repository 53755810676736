// Base
html {
  height: 100vh;
  scroll-behavior: smooth;
}

body {
  -webkit-font-smoothing: antialiased;
  min-height: 100vh;

  &.hidden,
  &.modal-open {
    overflow-y: hidden;
  }
}

.header {
  .header-menu-mobi {
    top: 102px;
  }

  &--with-banner {
    .header-menu-mobi {
      top: 122px;
    }
  }
}
