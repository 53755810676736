// Helpers
.bg-stripe {
  background: var(--bg-stripe);
}

.hr {
  background-color: var(--rrs-gray-100);
  height: 1px;
  width: 100%;
}

.list-none {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.text-truncate--2 {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  overflow: hidden;
  white-space: normal;
}

.cursor-pointer {
  cursor: pointer;
}

.z-1000 {
  z-index: 1000;
}

.z-2000 {
  z-index: 2000;
}

.no-pointer-events {
  pointer-events: none;
}
.spinner-size {
  height: 8rem;
  width: 8rem;
}
