.p-w-r {
  font-family: Montserrat, sans-serif !important;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    text-transform: capitalize !important;
  }

  h1,
  h2 {
    font-size: 1.25rem !important;
    font-weight: 400 !important;
  }

  .pr-snippet {
    align-items: flex-end !important;
    display: flex !important;
    flex-direction: column !important;

    .pr-snippet-stars-container {
      .pr-snippet-stars-png {
        float: right !important;
      }

      .pr-snippet-rating-decimal {
        border-color: var(--rrs-gray-50) !important;
      }
    }

    .pr-snippet-read-and-write {
      .pr-snippet-review-count {
        background: 0 0 !important;
        border-right: hsla(0, 0%, 42%, 1) solid 1px !important;
        color: hsla(0, 0%, 42%, 1) !important;
        font-size: 14px !important;
        font-weight: 700 !important;
        padding: 0 !important;
        padding-right: 0.75rem !important;
        text-decoration: underline !important;
      }

      .pr-snippet-write-review-link {
        background: 0 0 !important;
        color: hsla(0, 0%, 42%, 1) !important;
        font-size: 14px !important;
        font-weight: 700 !important;
        padding: 0 !important;
        padding-left: 0.75rem !important;
        text-decoration: underline !important;
      }
    }
  }

  .pr-rating-stars {
    .pr-star-v4-0-filled {
      background-image: url(/assets/icons/star-empty.svg) !important;
    }

    .pr-star-v4-25-filled {
      background-image: url(/assets/icons/star-half.svg) !important;
    }

    .pr-star-v4-50-filled {
      background-image: url(/assets/icons/star-half.svg) !important;
    }

    .pr-star-v4-75-filled {
      background-image: url(/assets/icons/star-half.svg) !important;
    }

    .pr-star-v4-100-filled {
      background-image: url(/assets/icons/star.svg) !important;
    }
  }

  .pr-snippet-stars-reco-reco {
    .pr-snippet-reco-to-friend {
      float: right !important;
      padding: 0.75rem 0 !important;
    }
  }

  .pr-snippet-slider-root {
    margin: 0 !important;
    justify-content: flex-end !important;

    .pr-snippet-title {
      display: none !important;
    }
  }

  .pr-review-snapshot-block-pros,
  .pr-review-snapshot-block-cons {
    border: none !important;
  }

  .pr-review-snapshot-header {
    .pr-review-snapshot-header-intro {
      border-bottom: 1px solid var(--rrs-gray-50) !important;
    }

    .pr-headline {
      font-size: 2rem !important;
      text-transform: capitalize !important;
    }

    .pr-subheadline a {
      color: var(--rrs-accent) !important;
    }
  }

  .pr-review-snapshot-snippets {
    .pr-snippet {
      align-items: flex-start !important;

      .pr-snippet-review-count {
        font-weight: 400 !important;
        text-decoration: none !important;
      }
    }
  }

  .pr-review-snapshot-block-container {
    margin-bottom: 2rem !important;
  }

  .pr-review-snapshot-block-headline,
  .pr-review-snapshot-block-headline,
  .pr-review-snapshot .pr-review-snapshot-block dt {
    background: var(--rrs-white) !important;
  }

  .pr-review-snapshot .pr-ratings-histogram div.pr-ratings-histogram-barValue {
    background-color: var(--rrs-accent);
  }

  p {
    color: #767676 !important;
    font-size: 11px !important;
  }

  .pr-review-snapshot-block-histogram {
    .pr-ratings-histogram p {
      text-transform: uppercase !important;
    }
  }

  .pr-review-snapshot-msq {
    border-spacing: 0 !important;
    padding-right: 1% !important;

    .pr-histogram-label {
      padding-left: 0 !important;
    }
  }

  .pr-review-snapshot-tags,
  .pr-review-snapshot-msqs {
    dd {
      align-items: center;
      display: inline-flex !important;

      .pr-snapshot-highlight {
        align-items: center !important;
        border-radius: 50% !important;
        border: 1px solid var(--rrs-gray-400) !important;
        display: flex !important;
        font-size: 0.75rem !important;
        height: 2rem !important;
        justify-content: center !important;
        padding: 0 !important;
        width: 2rem !important;
        font-weight: 400 !important;
        background: none !important;
      }
    }
  }

  .pr-review-snapshot-faceoff {
    border: none !important;

    h1,
    h2 {
      font-size: 14px !important;
      font-weight: 700 !important;
    }

    .pr-faceoff-img {
      border: none !important;
    }

    .pr-faceoff-img-txt {
      border: none !important;
      font-weight: 700 !important;
    }

    .pr-faceoff-positive {
      padding-left: 0 !important;
    }

    .pr-faceoff-negative {
      padding-right: 0 !important;
    }

    button.pr-faceoff-readcomplete {
      color: var(--rrs-primary);
    }
  }

  .pr-rd-main-header {
    background: var(--bg-stripe) !important;
  }

  .pr-rd-header.pr-rd-content-block {
    display: flex !important;
    flex-direction: column !important;

    h2 {
      font-size: 1.125rem !important;
      font-weight: 400 !important;
      margin: 0 !important;
    }
  }

  .pr-rd-reviewer-details,
  .pr-rd-details {
    font-size: 10px !important;
  }

  button.pr-rd-flag-review-btn {
    color: var(--rrs-primary);
  }

  .pr-accordion-btn span,
  .pr-caret-icon {
    color: var(--rrs-black) !important;
    font-size: 14px !important;

    .pr-caret-icon__line {
      stroke: var(--rrs-black) !important;
    }
  }

  .pr-helpful-btn {
    border: none !important;
    display: inline-flex !important;
    align-items: center !important;

    &.pr-helpful-yes {
      .pr-thumbs-icon {
        order: 2 !important;
      }
      .pr-helpful-count {
        order: 1 !important;
      }
    }
  }

  p.pr-rd-reviewer-type {
    align-items: center !important;
    display: inline-flex !important;
  }

  .pr-badging-icon {
    width: 1rem !important;
    height: 1.25rem !important;
  }

  .pr-badging-icon circle {
    stroke: var(--rrs-white);
    fill: var(--rrs-primary);
  }
  .pr-badging-icon circle path {
    fill: var(--rrs-black);
  }

  span.pr-helpful-count {
    align-items: center;
    background: var(--rrs-black) !important;
    border-radius: 14px;
    border: 1px solid var(--rrs-black);
    color: var(--rrs-white) !important;
    display: inline-flex;
    font-size: 0.5rem;
    height: 25px;
    justify-content: center;
    width: 25px;
  }

  .pr-no-reviews {
    .pr-snippet-review-count {
      display: none !important;
    }
  }

  .pr-rd-no-reviews {
    .pr-snippet-write-review-link {
      background-color: var(--rrs-black) !important;
      color: var(--rrs-white) !important;
      text-decoration: none !important;
      padding: 0.5rem 1rem !important;
    }
  }

  .pr-rating-input-container {
    display: block !important;
  }

  .pr-rd-to-top {
    color: var(--rrs-primary) !important;
  }

  .pr-rd-pagination {
    .pr-rd-pagination-btn {
      background-color: var(--rrs-black) !important;
      color: var(--rrs-white) !important;
      text-decoration: none !important;
      padding: 0.5rem 1rem !important;

      span {
        display: none !important;
      }
    }
  }

  .pr-rd-main-footer {
    border-bottom: 1px solid var(--rrs-gray-50) !important;
  }
  .pr-flagging-radiogroup {
    display: block !important;
  }
}
