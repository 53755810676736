cx-media {
  display: block;
  height: 100%;
  width: 100%;

  img {
    height: 100%;
    object-fit: contain;
    object-position: center;
    width: 100%;
  }

  &.is-missing {
    @extend %cx-media-missing !optional;
    height: 100%;
  }
}

%cx-media-missing {
  background-image: url("data:image/svg+xml,%3Csvg width='100' height='100' viewBox='0 0 100 100' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='100' height='100' rx='2' fill='%23E9E9E9'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M59.6921 37.5859C61.2163 37.5859 62.4634 38.8273 62.4634 40.3446V59.6549C62.4634 61.1721 61.2163 62.4135 59.6921 62.4135H40.2928C38.7686 62.4135 37.5215 61.1721 37.5215 59.6549V40.3446C37.5215 38.8273 38.7686 37.5859 40.2928 37.5859H59.6921ZM48.6068 56.2204L45.1426 52.0687L40.2928 58.2756H59.6921L53.4566 49.9997L48.6068 56.2204Z' fill='%23757575'/%3E%3C/svg%3E%0A");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
