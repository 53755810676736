._acs._acsbadge--default._acsbadge--small {
  display: none !important;
}

#oo_waypoint_prompt {
  .waypoint_icon {
    font-family: 'Open Sans', Verdana, Arial, Helvetica, sans-serif !important;
    font-size: 1rem !important;
    font-weight: 600 !important;
    line-height: 55px !important;
    text-transform: uppercase !important;
  }

  #oo_waypoint_content {
    p {
      font-family: 'Open Sans', Verdana, Arial, Helvetica, sans-serif !important;
      font-weight: 600 !important;
      text-transform: uppercase !important;
    }
    #ol_waypoint_brand_logo {
      p {
        font-family: 'Open Sans', Arial, Helvetica, sans-serif !important;
        font-weight: 400 !important;
        font-size: 1rem !important;
        text-transform: initial !important;
      }
    }
    .waypoint_link_icon {
      height: 55px !important;
    }
  }
}

#oo_tab {
  font-family: 'Open Sans', Verdana, Arial, Helvetica, sans-serif !important;
  font-size: 0.75rem !important;
  font-weight: 600 !important;
  text-transform: uppercase !important;
  z-index: 1030 !important;

  &.oo_tab_right {
    bottom: inherit !important;
    padding: 5px 10px 12px 10px !important;
    top: 19em !important;
  }
}
