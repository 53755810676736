body {
  &.searchbox-is-active {
    .searchbox__container {
      .searchbox__icon {
        &--close {
          display: none;
        }

        &--search {
          display: flex;
        }
      }

      &--isDirty {
        .searchbox__icon {
          &--close {
            display: flex;
          }

          &--search {
            display: none;
          }
        }
      }
    }

    &.has-searchbox-results {
      .searchbox-results {
        display: block;
        width: 99%;
      }
    }
  }

  &:not(.searchbox-is-active) {
    .searchbox__container {
      .searchbox__icon {
        &--close {
          display: none;
        }

        &--search {
          display: flex;
        }
      }

      &--isDirty {
        .searchbox__icon {
          &--close {
            display: block;
          }

          &--search {
            display: none;
          }
        }
      }
    }
  }
}
