cx-page-layout.CartPageTemplate {
  width: 100%;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  padding: 1rem;
  @include media-breakpoint-down(lg) {
    padding: unset;
  }
  .CenterRightContentSlot {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    justify-content: flex-end;
    padding-left: 1rem;
    flex: 1 1 var(--cx-flex-basis, 100%);
    max-width: var(--cx-flex-basis);

    > * {
      flex: 1 1, 100%;
      max-width: 100%;
    }

    --cx-flex-basis: 32.9%;

    @include media-breakpoint-down(lg) {
      padding-left: unset;
      --cx-flex-basis: 100%;
    }
  }

  .TopContent {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    flex: 1 1 var(--cx-flex-basis, 100%);
    max-width: var(--cx-flex-basis);

    > * {
      flex: 1 1 100%;
      max-width: 100%;
    }

    --cx-flex-basis: 67%;
    flex-direction: column;
    justify-content: flex-start;
    padding-right: 1rem;
    @include media-breakpoint-down(lg) {
      padding-right: unset;
      --cx-flex-basis: 100%;
    }
  }

  .bottom_content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    flex: 1 1 var(--cx-flex-basis, 100%);

    > * {
      flex: 1 1 var(--cx-flex-basis, 100%);
    }

    --cx-flex-basis: 100%;
    flex-direction: column;
    justify-content: flex-start;

    @include media-breakpoint-up(lg) {
      > * {
        flex: none;
      }
    }
  }
}
