.pulse {
  &,
  & span {
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 50%;
  }

  & span {
    animation: pulse-animation 1s linear infinite;
    display: block;
  }

  &--green,
  &--green span {
    background-color: var(--rrs-success);
  }

  &--red,
  &--red span {
    background-color: var(--rrs-danger);
  }
}

@keyframes pulse-animation {
  0% {
    opacity: 0.55;
    transform: scale(1);
  }
  25% {
    opacity: 0.45;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(2.5);
  }
}
