.modal {
  --rrs-modal-border-radius: 0.5rem;
  --rrs-modal-border-width: 0;
  --rrs-modal-footer-border-width: 0;
  --rrs-modal-header-border-width: 0;
  --rrs-modal-header-padding: 1.5rem 1rem;
  --rrs-modal-padding: 0 1rem 2rem;
}

.modal-backdrop {
  --rrs-backdrop-opacity: 0.35;
  width: 100%;
  height: 100%;
}

.modal-header {
  h4 {
    font-size: 1.25rem;
  }
}

@include media-breakpoint-down(sm) {
  .modal {
    --rrs-modal-border-radius: 1rem 1rem 0 0;
  }

  .modal-dialog {
    --rrs-modal-margin: 0;

    display: flex;
    align-items: flex-end;
    justify-content: center;
    height: 100%;
    backdrop-filter: blur(2px);
    // transition does not work properly for backdrop-filter
    animation: backdrop-filter-animation 0.3s ease-out;

    .modal.fade & {
      transform: translate(0, 50px);
    }
    .modal.show & {
      transform: none;
    }
  }
}

@keyframes backdrop-filter-animation {
  from {
    backdrop-filter: blur(0);
  }

  to {
    backdrop-filter: blur(2px);
  }
}

.product-details-zoom-modal {
  .modal-fullscreen {
    @include media-breakpoint-down(lg) {
      height: 60%;
    }
  }
}

.login-modal {
  @media (min-width: 577px) {
    .title {
      font-size: 12px;
    }

    .modal-dialog {
      max-width: 375px;
    }

    &.sticked-to-nav {
      .modal-dialog {
        max-width: 343px;
        position: fixed;
        margin: auto;
        height: 100%;
        right: 73px;
        top: 108px;
      }

      .modal-content:after {
        content: ' ';
        position: absolute;
        top: -8px;
        right: 45px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 6px 8px 6px;
        border-color: transparent transparent #ffffff transparent;
      }
    }
  }
}
