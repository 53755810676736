// Custom Variables
$color-black: #000;
$color-blue-light: #132b82;
$color-blue: #003da5;
$color-brown: #795014;
$color-gold: #927229;
$color-green: #14614b;
$color-orange: #ff6720;
$color-purple: #581479;
$color-red: #a42828;
$color-white: #fff;

$color-danger: $color-red;
$color-info: $color-blue;
$color-success: $color-green;
$color-warning: $color-gold;

$color-gray-50: #f5f5f5;
$color-gray-100: #eeeeee;
$color-gray-200: #e9e9e9;
$color-gray-300: #e5ecea;
$color-gray-400: #d1d7d5;
$color-gray-500: #d4d4d4;
$color-gray-600: #c2c2c2;
$color-gray-700: #999999;
$color-gray-800: #757575;
$color-gray-900: #585858;
$color-gray-1000: #333333;

$spacer: 1rem;
$spacers: (
  0: 0,
  1: $spacer * 0.25,
  2: $spacer * 0.5,
  3: $spacer,
  '3-5': $spacer * 1.25,
  4: $spacer * 1.5,
  5: $spacer * 3,
);
