@mixin alert-variant($background, $border, $color) {
  background-color: $background;
  border-color: $border;
  color: $color;
}
@each $state, $value in $theme-colors {
  $alert-background: shift-color($value, $alert-bg-scale);
  $alert-border: shift-color($value, $alert-border-scale);
  $alert-color: $color-black;

  .alert-#{$state} {
    @include alert-variant($alert-background, $alert-border, $alert-color);
  }
}

.alert {
  --rrs-alert-border-radius: 0;
  --rrs-alert-margin-bottom: 0.25rem;
  --rrs-alert-padding-x: 1rem;
  --rrs-alert-padding-y: 0.5rem;

  line-height: 1.5rem;
}
