.popover {
  &.bs-popover-bottom {
    margin-top: 1rem;
  }
  &.bs-popover-left {
    margin-right: 1rem;
  }
  &.bs-popover-right {
    margin-left: 1rem;
  }
  &.bs-popover-top {
    margin-bottom: 1rem;
  }
}

.popover-header {
  border-bottom: 0;
  padding: 1.5rem 1rem;
}

.popover-body {
  padding: 0 1rem 2rem;
}
