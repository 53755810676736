.CheckoutPageTemplate {
  footer.storefront-footer,
  rrs-scroll-to-top,
  .header_banner_slot {
    display: none;
  }

  rrs-checkout-payment-form {
    .card-body {
      padding: 0.5rem 0;
    }
  }
}

.OrderSummaryPageTemplate {
  footer.storefront-footer,
  rrs-scroll-to-top,
  .header_banner_slot {
    display: none;
  }
}
