// Checks and Radios
.form-check-label {
  font-size: $font-size-sm;
}

.form-switch {
  align-items: center;
  display: flex;

  .form-check-input {
    border-color: var(--rrs-gray-600);
    height: 1.5rem;
    margin-right: 0.5rem;
    width: 3.5rem;
    cursor: pointer;

    &:checked {
      background-color: var(--rrs-white);
      border-color: var(--rrs-gray-600);
    }
  }
}

// Inputs
input.form-control.is-valid,
input.form-control.is-invalid {
  background-size: 1rem;
  background-position: right 1rem center;
}

input[disabled].form-control {
  padding-right: $input-height-inner;
  background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M6.99683 0.333313C3.29989 0.333313 0.299469 3.31998 0.299469 6.99998C0.299469 10.68 3.29989 13.6666 6.99683 13.6666C10.6938 13.6666 13.6942 10.68 13.6942 6.99998C13.6942 3.31998 10.6938 0.333313 6.99683 0.333313ZM1.63894 6.99998C1.63894 4.05331 4.0366 1.66665 6.99683 1.66665C8.23585 1.66665 9.3744 2.08665 10.2785 2.79331L2.7708 10.2666C2.06088 9.36665 1.63894 8.23331 1.63894 6.99998ZM3.71513 11.2066C4.61927 11.9133 5.75782 12.3333 6.99683 12.3333C9.95707 12.3333 12.3547 9.94665 12.3547 6.99998C12.3547 5.76665 11.9328 4.63331 11.2229 3.73331L3.71513 11.2066Z' fill='%23C2C2C2'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: right 1rem center;
  background-size: 1rem;
}

.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-control-plaintext ~ label,
.form-floating > .form-select ~ label {
  opacity: initial;
  background-color: $color-white;
  text-transform: uppercase;
  font-weight: 500;
  width: auto;
  height: auto;
  padding: 2px;
}

// TextArea
.form-floating > textarea.form-control:focus,
.form-floating > textarea.form-control:not(:placeholder-shown),
.form-floating > textarea.form-control-plaintext:focus,
.form-floating > textarea.form-control-plaintext:not(:placeholder-shown) {
  padding-top: 0.75rem;
}

textarea.form-control.is-valid,
textarea.form-control.is-invalid {
  background-image: none;
}

textarea.form-control:focus {
  box-shadow: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
  -webkit-text-fill-color: #000;
  transition: background-color 5000s ease-in-out 0s;
}
