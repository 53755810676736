@import 'swiper/swiper';
@import 'swiper/modules/navigation/navigation';
@import 'swiper/modules/pagination/pagination';
@import 'swiper/modules/zoom/zoom';
@import 'swiper/modules/effect-fade/effect-fade';

.swiper-button-prev,
.swiper-button-next {
  align-items: center;
  background-color: $color-gray-50;
  color: $color-gray-800;
  display: flex;
  height: 2rem;
  justify-content: center;
  margin-top: 0;
  top: calc(50% - 1rem);
  transition: background-color 300ms ease;
  width: 2rem;

  &::after {
    font-size: 0.875rem;
    font-weight: bold;
  }

  &:hover {
    background-color: $color-black;

    &:after {
      color: $color-white;
    }
  }
}

.swiper-button-prev {
  left: 0;
}

.swiper-button-next {
  right: 0;
}

.swiper-button-disabled {
  display: none !important;
}

.swiper-pagination-bullet {
  background-color: $color-gray-700;
  height: 0.75rem;
  width: 0.75rem;
}

.swiper-pagination-bullet-active {
  background-color: $color-gray-900;
}

// Component Specific Styles - Doesn't work in Component SCSS Files
.swiper-slide-thumb-active {
  .product-details-images__item {
    border: 1px solid var(--rrs-black) !important;
  }
}

.product-variants-colors {
  .swiper-button-prev,
  .swiper-button-next {
    @include media-breakpoint-down(lg) {
      display: none;
    }
  }

  .swiper-slide {
    height: 4.5rem;
    width: 4.5rem;
  }
}

// Swiper with Arrow Outside
.swiper-arrow-container {
  position: relative;

  .swiper-arrow-prev,
  .swiper-arrow-next {
    align-items: center;
    color: $color-gray-800;
    cursor: pointer;
    display: flex;
    height: 2rem;
    justify-content: center;
    position: absolute;
    top: calc(50% - 1rem);
    width: 1.5rem;
  }

  .swiper-arrow-prev {
    left: -1.5rem;
  }
  .swiper-arrow-next {
    right: -1.5rem;
  }
}

.swiper-fade {
  .swiper-slide {
    pointer-events: none;
    transition-property: opacity;
    opacity: 0 !important;

    .swiper-slide {
      pointer-events: none;
    }
  }
  .swiper-slide-active {
    opacity: 1 !important;
    &,
    & .swiper-slide-active {
      pointer-events: auto;
    }
  }
}
