p {
  font-size: $font-size-sm;
}

span {
  font-size: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  text-transform: uppercase;
}

a {
  color: $color-black;
  text-decoration: none;

  &:hover {
    color: $color-black;
    text-decoration: underline;
  }

  &.nolink {
    &:hover {
      text-decoration: none;
    }
  }
}
