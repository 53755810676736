.pagination {
  --rrs-pagination-active-bg: var(--rrs-white);
  --rrs-pagination-active-border-color: var(--rrs-black);
  --rrs-pagination-active-color: var(--rrs-black);
  --rrs-pagination-bg: var(--rrs-white);
  --rrs-pagination-border-color: var(--rrs-white);
  --rrs-pagination-border-radius: 0;
  --rrs-pagination-border-width: 0.125rem;
  --rrs-pagination-color: var(--rrs-black);
  --rrs-pagination-disabled-bg: var(--rrs-white);
  --rrs-pagination-disabled-border-color: var(--rrs-white);
  --rrs-pagination-disabled-color: var(--rrs-gray-700);
  --rrs-pagination-focus-bg: var(--rrs-white);
  --rrs-pagination-focus-box-shadow: 0 0 0 0.25rem rgba(0, 0, 0, 0.25);
  --rrs-pagination-focus-color: var(--rrs-black);
  --rrs-pagination-font-size: 1rem;
  --rrs-pagination-hover-bg: var(--rrs-white);
  --rrs-pagination-hover-border-color: var(--rrs-black);
  --rrs-pagination-hover-color: var(--rrs-primary);
  --rrs-pagination-padding-x: 0.125rem;
  --rrs-pagination-padding-y: 0.125rem;

  gap: 0.5rem;

  .page-item {
    &.active {
      .page-link {
        border-color: var(--rrs-pagination-active-border-color) !important;
        color: var(--rrs-pagination-active-color) !important;
      }
    }

    &.disabled {
      cursor: not-allowed;
    }

    &:not(.active) {
      &:hover {
        text-decoration: underline;
      }
    }
  }

  .page-link {
    align-items: center;
    display: flex;
    height: 2rem;
    justify-content: center;
    min-width: 2rem;
  }

  // Ng Boostrap 10
  .sr-only {
    display: none;
  }
}
