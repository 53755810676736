.hamburger {
  cursor: pointer;

  &__container {
    align-items: center;
    display: flex;
    height: 2rem;
    justify-content: center;
    position: relative;
    width: 2rem;
  }

  &__bars {
    background-color: var(--rrs-white);
    height: 0.125rem;
    position: absolute;
    transition: transform 300ms ease;
    width: 2rem;

    &::before,
    &::after {
      background-color: var(--rrs-white);
      content: '';
      display: block;
      height: 0.125rem;
      position: absolute;
      width: 2rem;
    }

    &::before {
      top: -0.5rem;
      transition: top 100ms 250ms ease-in, transform 220ms ease;
    }

    &::after {
      bottom: -0.5rem;
      transition: bottom 100ms 250ms ease-in, transform 220ms ease;
    }
  }

  &--active {
    .hamburger__bars {
      transform: rotate(225deg);
      transition: transform 220ms 120ms ease;

      &::before {
        top: 0;
        transition: top 100ms ease-out;
      }

      &::after {
        bottom: 0;
        transform: rotate(-90deg);
        transition: bottom 100ms ease-out, transform 220ms 120ms ease;
      }
    }
  }
}
