// Forms
$form-check-input-bg: $color-white;
$form-check-input-border-radius: 0;
$form-check-input-border: 1px solid $color-black;
$form-check-input-checked-bg-color: $color-black;
$form-check-input-checked-border-color: $color-black;
$form-check-input-checked-color: $color-white;
$form-check-input-disabled-opacity: 0.25;
$form-check-input-focus-border: tint-color($color-black, 50%);
$form-check-input-width: 1.125em;

$form-feedback-font-size: $h6-font-size;
$form-feedback-font-style: italic;
$form-feedback-valid-color: $color-success;
$form-feedback-invalid-color: $color-danger;
$form-feedback-margin-top: 0.25rem;

$form-floating-height: 3rem;
$form-floating-padding-y: $input-padding-y;
$form-floating-input-padding-t: 0;
$form-floating-input-padding-b: 0;
$form-floating-label-transform: scale(0.85) translateY(-1rem) translateX(0.5rem);

$form-label-color: $color-black;
$form-label-font-size: $h6-font-size;
$form-label-font-weight: 700;
$form-label-font-weight: 700;
$form-label-margin-bottom: 0.25rem;

$form-switch-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$color-gray-400}'/></svg>");
$form-switch-checked-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$color-black}'/></svg>");
$form-switch-color: $color-black;
$form-switch-focus-color: $color-black;
$form-switch-width: 3.5em;
