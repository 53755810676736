// Navigation Tabs
$nav-tabs-border-color: $color-white;
$nav-tabs-border-radius: 0;
$nav-tabs-border-width: 0;
$nav-tabs-link-active-bg: $color-gray-300;
$nav-tabs-link-active-color: $color-black;

.nav-tabs {
  align-items: center;
  display: flex;
  justify-content: center;

  .nav-link {
    color: $color-black;
    font-size: 0.875rem;
    padding: 0.75rem;
    text-decoration: none;
    text-transform: uppercase;

    &:hover {
      color: $color-black;
      text-decoration: none;
    }
  }
}
