// Due to the use of NgBootstrap 10, we are using styles and variables from Bootstrap version 4. TODO: When upgrade NgBootstrap use variables from Bootstrap version 5.
$tooltip-arrow-color: var(--rrs-black);
$tooltip-arrow-height: 0.4rem;
$tooltip-arrow-width: 0.8rem;
$tooltip-bg: var(--rrs-black);
$tooltip-border-radius: 0.125rem;
$tooltip-color: var(--rrs-white);
$tooltip-font-size: 0.875rem;
$tooltip-margin: 0;
$tooltip-max-width: 10rem;
$tooltip-opacity: 0.9;
$tooltip-padding-x: 0.75rem;
$tooltip-padding-y: 0.5rem;

.tooltip {
  margin: $tooltip-margin;

  .arrow {
    position: absolute;
    display: block;
    width: $tooltip-arrow-width;
    height: $tooltip-arrow-height;

    &::before {
      position: absolute;
      content: '';
      border-color: transparent;
      border-style: solid;
    }
  }
}

.tooltip-inner {
  background-color: $tooltip-bg;
  border-radius: $tooltip-border-radius;
  color: $tooltip-color;
  max-width: $tooltip-max-width;
  padding: $tooltip-padding-y $tooltip-padding-x;
  text-align: center;
}

.bs-tooltip-top {
  padding: $tooltip-arrow-height 0;

  .arrow {
    bottom: 0;

    &::before {
      top: 0;
      border-width: $tooltip-arrow-height ($tooltip-arrow-width * 0.5) 0;
      border-top-color: $tooltip-arrow-color;
    }
  }
}

.bs-tooltip-right {
  padding: 0 $tooltip-arrow-height;

  .arrow {
    left: 0;
    width: $tooltip-arrow-height;
    height: $tooltip-arrow-width;

    &::before {
      right: 0;
      border-width: ($tooltip-arrow-width * 0.5) $tooltip-arrow-height
        ($tooltip-arrow-width * 0.5) 0;
      border-right-color: $tooltip-arrow-color;
    }
  }
}

.bs-tooltip-bottom {
  padding: $tooltip-arrow-height 0;

  .arrow {
    top: 0;

    &::before {
      bottom: 0;
      border-width: 0 ($tooltip-arrow-width * 0.5) $tooltip-arrow-height;
      border-bottom-color: $tooltip-arrow-color;
    }
  }
}

.bs-tooltip-left {
  padding: 0 $tooltip-arrow-height;

  .arrow {
    right: 0;
    width: $tooltip-arrow-height;
    height: $tooltip-arrow-width;

    &::before {
      left: 0;
      border-width: ($tooltip-arrow-width * 0.5) 0 ($tooltip-arrow-width * 0.5)
        $tooltip-arrow-height;
      border-left-color: $tooltip-arrow-color;
    }
  }
}

.bs-tooltip-auto {
  &[x-placement^='top'] {
    @extend .bs-tooltip-top;
  }
  &[x-placement^='right'] {
    @extend .bs-tooltip-right;
  }
  &[x-placement^='bottom'] {
    @extend .bs-tooltip-bottom;
  }
  &[x-placement^='left'] {
    @extend .bs-tooltip-left;
  }
}
