button {
  background-color: transparent;
  border: 0;
  margin: 0;
  padding: 0;
  color: black;
}

a.btn {
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}

.btn {
  --rrs-btn-border-radius: 0;
  --rrs-btn-font-size: 0.875rem;
  --rrs-btn-font-weight: 700;
  --rrs-btn-line-height: 1.5rem;
  --rrs-btn-padding-x: 1.5rem;
  --rrs-btn-padding-y: 0.75rem;

  --rrs-btn-disabled-bg: var(--rrs-gray-500);
  --rrs-btn-disabled-border-color: var(--rrs-gray-500);
  --rrs-btn-disabled-color: var(--rrs-white);

  align-items: center;
  display: inline-flex;
  justify-content: center;
  position: relative;

  &:not(.btn-icon) {
    svg {
      margin: 0 0.5rem;
    }
  }
}

.btn-link {
  --rrs-btn-font-weight: 400;
  --rrs-btn-line-height: 1rem;
  --rrs-btn-padding-x: 0.5rem;
  --rrs-btn-padding-y: 0.25rem;

  &:disabled {
    color: var(--rrs-gray-500) !important;
  }
}

// Theme Buttons
.btn-primary {
  --rrs-btn-active-bg: var(--rrs-black);
  --rrs-btn-active-border-color: var(--rrs-black);
  --rrs-btn-active-color: var(--rrs-white);

  --rrs-btn-hover-bg: var(--rrs-black);
  --rrs-btn-hover-border-color: var(--rrs-black);
  --rrs-btn-hover-color: var(--rrs-white);

  border-radius: 0.125rem;
  width: 100%;
}

.btn-secondary {
  --rrs-btn-border-color: var(--rrs-black);

  --rrs-btn-active-bg: var(--rrs-white);
  --rrs-btn-active-border-color: var(--rrs-black);
  --rrs-btn-active-color: var(--rrs-black);

  --rrs-btn-hover-bg: var(--rrs-white);
  --rrs-btn-hover-border-color: var(--rrs-black);
  --rrs-btn-hover-color: var(--rrs-black);

  border-radius: 0.125rem;
  width: 100%;
}

// Variant Buttons
.btn-icon {
  --rrs-btn-line-height: 1rem;
  --rrs-btn-padding-x: 0.5rem;
  --rrs-btn-padding-y: 0.5rem;

  width: fit-content;
}

.btn-sm {
  --rrs-btn-padding-x: 1rem;
  --rrs-btn-padding-y: 0.5rem;

  width: fit-content;
}

.btn-size {
  --rrs-btn-font-weight: 400;
  --rrs-btn-line-height: 1.5rem;
  --rrs-btn-padding-x: 0.25rem;
  --rrs-btn-padding-y: 0.25rem;

  --rrs-btn-bg: var(--rrs-white);
  --rrs-btn-border-color: var(--rrs-gray-200);
  --rrs-btn-color: var(--rrs-black);

  --rrs-btn-active-bg: var(--rrs-white);
  --rrs-btn-active-border-color: var(--rrs-black);
  --rrs-btn-active-color: var(--rrs-black);

  --rrs-btn-hover-bg: var(--rrs-black);
  --rrs-btn-hover-border-color: var(--rrs-black);
  --rrs-btn-hover-color: var(--rrs-white);

  height: 2.5rem;
  width: 2.5rem;

  &:disabled {
    --rrs-btn-disabled-bg: var(--rrs-gray-50);
    --rrs-btn-disabled-border-color: var(--rrs-gray-700);
    --rrs-btn-disabled-color: var(--rrs-gray-700);

    background: linear-gradient(
        to top right,
        #ffffff00 calc(50% - 1px),
        var(--rrs-gray-700),
        #ffffff00 calc(50% + 1px)
      ),
      var(--rrs-gray-50);
    overflow: hidden;
    position: relative;
  }

  &--active {
    --rrs-btn-bg: var(--rrs-black);
    --rrs-btn-border-color: var(--rrs-black);
    --rrs-btn-color: var(--rrs-white);
  }
  &--wide {
    width: 100%;
  }
}

.btn {
  &.with-loader {
    &:disabled {
      opacity: 1;
    }
  }

  // loader icon inside button
  rrs-loader {
    svg {
      width: 24px;
      height: 24px;
      animation-name: rotate;
      animation-duration: 1s;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
    }
  }
}

// Updating text to caps on all variations of creating a button
button,
.btn,
a.btn {
  text-transform: uppercase;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
