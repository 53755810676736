@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

$ng-select-highlight: $color-black;
$ng-select-primary-text: $color-black;
$ng-select-disabled-text: $color-gray-500;
$ng-select-border: $color-gray-800;
$ng-select-border-radius: 0.125rem;
$ng-select-bg: $color-white;
$ng-select-selected: $color-gray-50;
$ng-select-selected-text: $ng-select-primary-text;

$ng-select-marked: $color-gray-50;
$ng-select-marked-text: $ng-select-primary-text;

$ng-select-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
  0 0 0 3px rgba(0, 126, 255, 0.1) !default;
$ng-select-placeholder: $color-gray-800;
$ng-select-height: 2.8rem;
$ng-select-value-padding-left: 10px !default;
$ng-select-value-font-size: $font-size-base;
$ng-select-value-text: $ng-select-primary-text !default;

$ng-select-dropdown-optgroup-text: rgba(0, 0, 0, 0.54) !default;
$ng-select-dropdown-option-text: rgba(0, 0, 0, 0.87) !default;
$ng-select-dropdown-option-disabled: $color-gray-800;

$ng-select-input-text: $color-black;

@import '@ng-select/ng-select/scss/default.theme';

.ng-select {
  &.ng-invalid.ng-touched {
    .ng-select-container {
      border-color: $color-danger;
      box-shadow: none;
    }
  }

  &.ng-valid {
    .ng-value-label {
      color: $color-black !important;
    }
  }

  .ng-select-container.ng-has-value {
    border-color: $color-black;
  }

  &.ng-select-focused {
    .ng-dropdown-panel {
      border-color: $color-black;
    }

    .ng-select-container {
      border-color: $color-black;
      color: $color-gray-600 !important;
    }
  }

  &.ng-select-disabled {
    .ng-arrow {
      background-image: url('/assets/icons/select/disabled.svg');
    }

    .ng-select-container {
      background-color: $color-gray-50;
      border-color: $color-gray-500;
      color: $color-gray-500 !important;
    }
  }

  &.ng-select-opened {
    .ng-arrow {
      color: $color-gray-600;
      transform: rotate(180deg);
    }
  }

  .ng-value {
    padding-inline-end: 2rem;
    &-label {
      color: $color-gray-800 !important;
    }
  }

  .ng-arrow-wrapper {
    align-items: center;
    display: flex;
    height: 2rem;
    justify-content: center;
    padding: 0 !important;
    position: absolute !important;
    right: 0.25rem;
    top: calc(50% - 1rem);
    width: 2rem;
  }

  .ng-arrow {
    background-image: url('/assets/icons/select/carret.svg');
    background-position: center;
    background-repeat: no-repeat;
    border-width: 0 !important;
    height: 1rem !important;
    width: 1rem !important;
  }

  .ng-option {
    padding-inline-end: 2rem !important;

    &-selected {
      position: relative;

      &:after {
        background-image: url('/assets/icons/select/mark.svg');
        background-position: center;
        background-repeat: no-repeat;
        border-width: 0 !important;
        content: '';
        height: 2rem !important;
        position: absolute;
        right: 0.25rem;
        top: calc(50% - 1rem);
        width: 2rem !important;
      }
    }
  }
}

// Select Variants Styles - Not Working In Component Stylesheets
.plp {
  @include media-breakpoint-down(lg) {
    .ng-dropdown-panel {
      min-width: 10rem;
      left: unset;
      right: 0;
    }
    & .ng-value-container,
    .ng-arrow-wrapper {
      display: none !important;
    }
    & .ng-select-container {
      border-radius: 0;
      min-height: 0 !important;
      height: auto !important;
      padding-inline: 1rem;
      padding-block: 0.5rem;
      padding: 0.25rem 0.5rem !important;
      border: 1px solid var(--rrs-gray-600) !important;
      &::after {
        line-height: 1.25;
        z-index: 1002;
        width: 100%;
        text-align: center;
        font-weight: 700;
        display: block;
        content: 'Sort';
      }
    }
  }
}
