main .CoreContentPageTemplate {
  display: flex;
  flex-wrap: wrap;

  & > .hero_banner {
    width: 100%;
  }

  & > .content_navigation {
    display: none;

    @include media-breakpoint-up(lg) {
      display: flex;
      flex: 1 1 50%;
      margin-left: auto;
      max-width: 25rem;
      padding: 0 1rem;
    }
  }

  & > .main_content {
    display: flex;
    flex-direction: column;
    flex: 1 1 50%;
    margin-right: auto;
    max-width: 55rem;
    padding: 0 1rem;
  }
}
